module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Somali American Business Association","short_name":"SABA","start_url":"/","display":"minimal-ui","icon":"src/images/saba icon.png"},
    },{
      plugin: require('../node_modules/gatsby-source-notion-database/gatsby-browser.js'),
      options: {"plugins":[],"sourceConfig":[{"name":"resources","table":"https://www.notion.so/mohamedtwice/891e60376fba40eca8d070e6fcff5728?v=9f04dc47594142c79328a8785c1c5fe5","cacheType":"html"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-162138079-1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
